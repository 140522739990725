import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectChatComponent } from './project-chat.component';
import { ChatMessagesModule } from '../chat-messages/chat-messages.module';



@NgModule({
  declarations: [ProjectChatComponent],
  imports: [
    CommonModule,
    ChatMessagesModule
  ],
  exports: [ProjectChatComponent]
})
export class ProjectChatModule { }
