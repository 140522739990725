import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ASSETS, UserService, noDataContent } from '@conpulse-web/core';

@Component({
  selector: 'conpulse-web-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
})
export class NoContentComponent implements OnInit {
  @Input() data: noDataContent = {} as noDataContent;
  @Output() addEmitter = new EventEmitter();
  notFoundImage: string;

  constructor(private readonly userService: UserService) {
    this.notFoundImage = `${this.userService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
  }

  ngOnInit(): void {}
}
