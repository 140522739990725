import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationService } from 'libs/core/src/lib/notification';
import { includes, isEmpty, isEqual, pull, trim } from 'lodash-es';

@Component({
  selector: 'app-input-chip-instant-save',
  templateUrl: './input-chip-instant-save.component.html',
  styleUrls: ['./input-chip-instant-save.component.scss'],
})
export class InputChipInstantSaveComponent implements OnInit {
  isEmpty = isEmpty;
  isEqual = isEqual;

  selectedItems: string[] = [];
  deleteDisabledItems: boolean[] = [];
  editDisabledItems: boolean[] = [];
  @Input() type: string;
  @Input() category: string;
  @Input() matInputPlaceHolder: string;
  @Input() requiredErrorMsg: string;
  @Input() loading: boolean;
  @Input() showLess = false;
  @Input() intialLoader: boolean;
  @Input() isBrand = false;
  @Input() isMilestone = false;
  @Input() maxItems = 0;
  @Input() label = 'items';
  @Output() addItem = new EventEmitter();
  @Output() editItem = new EventEmitter();
  @Output() deleteItem = new EventEmitter();
  searchText: string;
  showMoreChips = false;
  isDisabled = false;

  // Tiers variables
  @Input() editTiers: boolean;
  editMode: boolean = false;
  oldItemValue: string = '';

  @Input()
  set markedItems(value: string[]) {
    if (value) {
      this.selectedItems = value;
      this.searchText = '';
      this.oldItemValue = '';
      this.editMode = false;
    }
  }

  @Input()
  set undeletableItems(value: boolean[]) {
    if (value) {
      this.deleteDisabledItems = value;
      this.searchText = '';
      this.oldItemValue = '';
      this.editMode = false;
    }
  }

  @Input() set editableItems(value: boolean[]){
    if (value) {
      this.editDisabledItems = value;
      this.searchText = '';
      this.oldItemValue = '';
      this.editMode = false;
    }
  }

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {}

  addRegion(form: NgForm) {
    this.searchText = trim(this.searchText);
    if (!isEmpty(this.searchText)) {
      if (includes(this.selectedItems, this.searchText) && !this.editMode) {
        return this.notificationService.openErrorSnackBar('Duplicate values entered!');
      }
      if (!this.editMode) {
        if (this.maxItems && this.maxItems === this.selectedItems?.length) {
          return this.notificationService.openErrorSnackBar(`Maximum of ${this.maxItems} ${this.label} are only allowed.`);
        }
        this.addItem.emit({ type: this.type, name: this.searchText, category: this.category });
      } else {
        this.isDisabled = includes(this.selectedItems, this.searchText);
        if (this.oldItemValue !== this.searchText && !this.isDisabled) {
          this.isDisabled = false;
          this.editItem.emit({ type: this.type, oldItem: this.oldItemValue, newItem: this.searchText, category: this.category });
        } else {
          this.isDisabled = false;
          return this.notificationService.openErrorSnackBar('Duplicate values entered!');
        }
      }
    }
  }

  removeCustomRegion(value: string) {
    const index = this.selectedItems.indexOf(value);
    // this.selectedItems = pull(this.selectedItems, value);
    if (this.undeletableItems?.length) this.undeletableItems.splice(index, 1);
    this.deleteItem.emit({ type: this.type, item: value });
  }

  /**
   * Begins editing of tier
   * @param oldValue The old value
   */
  editTier(oldValue: string) {
    this.editMode = true;
    this.searchText = oldValue;
    this.oldItemValue = oldValue;
  }

  /**
   * Cancels editing of a tier
   */
  cancelEdit() {
    this.editMode = false;
    this.searchText = '';
    this.oldItemValue = '';
  }
}
