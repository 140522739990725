import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ChatMessagesComponent } from './chat-messages.component';
import { ConpulseMaterialModule } from '../../material-module/index';
import { I18NextModule } from 'angular-i18next';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConpulseModule } from '../../conpulse.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NoContentModule } from '../no-content/no-content.module';


@NgModule({
  declarations: [ChatMessagesComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    ConpulseModule,
    I18NextModule,
    InfiniteScrollModule,
    PickerModule,
    NoContentModule
  ],
  exports: [ChatMessagesComponent],
  providers: [DatePipe]
})
export class ChatMessagesModule { }
