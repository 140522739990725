import { CONSTANTS } from '@conpulse-web/core';

export const environment = {
  production: true,
  APIEndpoint: 'https://stage-api.consource.io/api/',
  systemBase: 'https://stage.consource.io/',
  auth: {
    domain: 'consourse-stage.us.auth0.com',
    clientId: 'SsRGaY4ztGM5A8nRqE1AwyDd3EAHNIyH',
    audience: 'https://consourse-stage.us.auth0.com/api/v2/',
    redirectUri: 'https://stage.consource.io/',
  },
  socketUrl: 'https://stage-api.consource.io/',
  httpInterceptorUrl: 'https://stage-api.consource.io/api/*',
  loadFromSandboxData: false,
};
